const region = 'eu-central-1';
const userPoolId = 'eu-central-1_RukmAvqrB';
const userPoolWebClientId = 'rbqhlfuhq7jlarm7oljp6ni09';
const amazonConnectInstanceName = 'connectai-production';
const identityPoolId = 'eu-central-1:acc5a6bf-d945-430a-b680-424014f7be36';

export const environment = {
  production: false,
  apiUrl: 'https://vxr3cpon5a.execute-api.eu-central-1.amazonaws.com',
  instanceId: '28cd0ccd-08f3-4267-a84e-49412e46ce8c',
  AWS: {
    connect: {
      instanceUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/ccp-v2`,
      loginUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/auth/sign-in`,
      logoutUrl: `https://${amazonConnectInstanceName}.awsapps.com/connect/logout`
    },
    lex: {
      identityPoolId,
      chatBotNameGerman: 'UIAgentBotGerman',
      chatBotNameEnglish: 'UIAgentBotEnglish',
      lexRegion: region
    },
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'de',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
